<template>
    <div>
         <Header-top></Header-top>
         <div class="main">
             <div class="box">
                 <div class="boxTitle">
                    订单详情
                 </div>
                 <div class="ddStatus">
                     <div style="margin-left:36px">
                         订单编码：<i style="color:rgba(22, 119, 255, 1)">{{orderInfo.id}}</i>
                     </div>
                     <div>
                         订单状态：<i style="color:rgba(22, 119, 255, 1)">{{getStatus(orderInfo.weStatus)}}</i>
                     </div>
                     <div style="margin-right:36px">
                         提交时间：{{orderInfo.createTime}}
                     </div>
                 </div>
                 <div class="dcinfobox">
                     <div>
                        <!-- <div class="leftbox">
                            <div class="dctitle">
                                联系人信息
                            </div>
                            <div class="lxrbox">
                               <div class="lxrtittl">
                                   <div>
                                       
                                   </div>
                                    <div>
                                        联系人
                                    </div>
                                     <div>
                                        公司名称
                                    </div>
                                  
                                   
                                    <div>
                                        联系方式
                                    </div>
                               </div>
                                <div class="lxrtittl" style="padding-left:0;margin-top:15px">
                                    <div class="strong">
                                        订舱
                                    </div>
                                    <div>
                                        {{orderInfo.weContacts}}
                                    </div>
                                     <div>
                                        {{orderInfo.weCompanyName}}
                                    </div>
                                    <div>
                                          {{orderInfo.wePhone}}
                                    </div>
                                  
                               </div>
                                
                            </div>
                        </div> -->
                        <div class="leftbox mt20">
                            <div class="dctitle">
                                订舱人&预定信息
                            </div>
                            <div class="dcbox">
                                <div class="dc">
                                    <div>
                                        公司名称：{{orderInfo.weCompanyName}}
                                    </div>
                                    <div>
                                        联系人：{{orderInfo.weContacts}}
                                    </div>
                                    
                                </div>
                                <div class="dc mt12">
                                    <div>
                                        联系电话：{{orderInfo.wePhone}}
                                    </div>
                                    <div>
                                        预估货重(T)：{{orderInfo.weProductWeight}}
                                    </div>
                                </div>
                                <div class="dc mt12">
                                    <div>
                                        预定柜量：{{orderInfo.weNum}}
                                    </div>
                                    <div>
                                       品名：{{orderInfo.weProductName}}
                                    </div>
                                </div>
                                 <div class=" mt12">
                                    <div style="margin-left:36px;">
                                        <div class="flex">
                                       <div style="margin-top:5px;width:50px;font-size:14px;font-weight:500"> 附件：  </div> <div class="flex" style="width:400px">
                                                    <div class="extra1"  v-show="orderInfo.weAnnexUrl">
                                                        <div>
                                                            <img src="./icon.png" alt="" srcset=""> 附件
                                                        </div>
                                                        <div class="dowonload" @click="downLoad(orderInfo.weAnnexUrl)">
                                                            <img src="./download.png" alt="" srcset="">
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                    </div>
                                    </div>
                                </div>
                                <!-- <div class="dc mt12">
                                    <div>
                                        中转方式：--
                                    </div>
                                    <div>
                                        运输条款：{{orderInfo.weTransTerms}}
                                    </div>
                                </div>
                                 <div class="dc mt12">
                                    <div>
                                        订单金额(USD)：{{orderInfo.weCabinetAmount * orderInfo.weNum}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="leftbox mt20">
                            <div class="dctitle">
                                预定柜子信息&数量
                            </div>
                            <div class="lxrbox">
                               <div class="lxrtittl">
                                    <div>
                                        箱 型
                                    </div>
                                    <div>
                                        箱 量
                                    </div>
                                    <div>
                                        货 重(T)
                                    </div>
                               </div>
                                <div class="lxrtittl" style="padding-left:0;margin-top:15px">
                                    <div>
                                        {{orderInfo.weCabinetShape}}
                                    </div>
                                    <div>
                                          {{orderInfo.weNum}}
                                    </div>
                                    <div>
                                          {{orderInfo.weProductWeight}}
                                    </div>
                               </div>
                            </div>
                        </div> -->
                         <div class="leftbox mt20">
                            <div class="dctitle">
                               航线信息
                            </div>
                            <div class="dcbox">
                                <div class="dc">
                                    <div>
                                        起运港：{{orderInfo.weStartSeaport}}
                                    </div>
                                   
                                    <div>
                                        中转港：<span v-show="orderInfo.weTargetSeaport == orderInfo.weChangeSeaport"> 直航</span>：<span v-show="orderInfo.weTargetSeaport != orderInfo.weChangeSeaport"> {{orderInfo.weChangeSeaport}}中转</span>
                                     </div>
                                </div>
                                 <div class="dc mt12">
                                      
                                     <div>
                                        目的港：{{orderInfo.weTargetSeaport}}
                                    </div>
                                    <div>
                                        船司：{{orderInfo.weShipCompanyName}}
                                    </div>
                                   
                                    
                                </div>
                                <div class="dc mt12">
                                    <div>
                                        截文件日期：{{(orderInfo.weCloseFileTime).substr(0, 10)}}
                                    </div>
                                      <div>
                                        截关日期：{{(orderInfo.weClosePassTime).substr(0, 10)}}
                                    </div>
                                   
                                </div>
                                <div class="dc mt12">
                                    <div>
                                        开船日期：{{(orderInfo.weSailTime).substr(0, 10)}}
                                    </div>
                                    <div>
                                        运价备注：{{orderInfo.weRemark}}
                                    </div>
                                    <!-- <div>
                                        箱型箱量：{{orderInfo.weCabinetShape}} X{{orderInfo.weNum}}
                                    </div> -->
                                </div>
                                <div class="dc mt12">
                                    <div>
                                        提单方式：{{orderInfo.weOrderMode}}
                                    </div>
                                      <div>
                                        付款方式：{{orderInfo.wePayMode}}
                                    </div>
                                </div>
                                <div class="dc mt12">
                                    <div>
                                        放货方式：{{orderInfo.weReleaseMode}}
                                    </div>
                                      <div>
                                        运输条款：{{orderInfo.weTransTerms}}
                                    </div>
                                </div>
                                 <div class="dc mt12">
                                    <div>
                                        航程：{{orderInfo.weVoyageDay}}
                                    </div>
                                      <div>
                                        柜型：{{orderInfo.weCabinetShape}}
                                    </div>
                                </div>
                                  <div class="dc mt12">
                                    <div>
                                        柜子价格：{{orderInfo.weCabinetAmount}}(USD)
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="leftbox mt20 qtsxx">
                            <div class="dctitle">
                               费用信息
                            </div>
                            <div class="dc mt12">
                                预定数量： {{orderInfo.weNum}}
                            </div>
                            <div class="dc mt4">
                               
                            </div>
                            <div class="dc mt12">
                                柜子单价(USD)： {{orderInfo.weCabinetAmount}}
                            </div>
                            <div class="dc mt4">
                               
                            </div>
                            <div class=" mt12" style="margin-left:36px">
                                附加费： <span>{{orderInfo.cnyTotal}}(CNY)</span> + <span>{{orderInfo.usdTotal}}(USD)</span>
                            </div>
                            <div class="dc mt4">
                              
                            </div>
                             <div class=" mt12" style="margin-left:36px" >
                                <div>
                                    总价：<span>{{orderInfo.cnyTotal}}(CNY)</span> + <span>{{orderInfo.usdTotal + orderInfo.weCabinetAmount * orderInfo.weNum}}(USD)</span>
                                   
                                </div>
                                    
                            </div>
                          
                        </div>
                     </div>
                     <div style="margin-left:20px" class="rightmian leftbox">
                         <div class="dctitle" style=" width: 400px;">
                               订单流程
                        </div>
                        <div style="margin-top:44px;margin-left:41px">
                            <el-timeline :reverse="reverse">
                                <el-timeline-item
                                v-for="(activity, index) in activities"
                                :key="index"
                                :color="activity.color"
                                :timestamp="activity.timestamp">
                               <div class="flex">
                                   <div style="width:120px"> {{activity.content}}</div> <div class="link" v-show="activity.url !='' && orderInfo.weStatus !=9" @click="downLoad(activity.url)">{{activity.urlText}}</div>
                               </div>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                     </div>
                 </div>
             </div>
         </div>
         <footerBottom></footerBottom>
    </div>
</template>
<script>
import HeaderTop from '../common/header.vue'
import footerBottom from '../common/footer.vue'
export default {
     components:{HeaderTop,footerBottom},
     data(){
         return{
                orderInfo:{},
                reverse:false,
                activities: [
                ],
                 tabs: [
                    {label: '已订舱',name: '1'},
                    {label: '已付押金',name: '2'},
                    {label: '已下SO',name: '3'},
                    {label: '已提交补料',name: '4'},
                    {label: '已提交VGM',name: '5'},
                    {label: '已发船',name: '6'},
                    {label: '已出账待付款',name: '7'},
                    {label: '交易成功',name: '8'},
                    {label: '已取消',name: '9'}
                ],
         }
     },
     mounted(){
         this.getData()
     },
     methods:{
         downLoad(url){
            const aLink = document.createElement('a')
            aLink.download = 'wenjia' 
            aLink.href = url 
            aLink.dispatchEvent(new MouseEvent('click', {}))
         },
          getStatus(status){
                for(var i=0;i<this.tabs.length;i++){
                    if(this.tabs[i].name==status){
                        return this.tabs[i].label;
                    }
                }
            },
          getData(){
            console.log(this.$route)
            this.$get('/workOrder/detail',{id:this.$route.query.orderId}).then(res =>{
                    this.orderInfo = res.data
                    let info = res.data
                    this.activities = [
                            {
                                content: '已订舱',
                                timestamp: info.createTime ,
                                color: '#0bbd87'
                            }, 
                            {
                                content: '已付押金',
                                // timestamp: '2018-04-11'
                                timestamp: info.cashFileTime,
                                color: info.cashFileTime ? '#0bbd87' : '',
                                url:info.cashFile ? info.cashFile :'',
                                urlText:'下载水单'
                            },
                            {
                                content: '已下SO',
                                timestamp: info.soFileTime,
                                color: info.soFileTime ? '#0bbd87' : '',
                                url:info.soFile ? info.soFile :'',
                                urlText:'下载SO文件'
                            },
                            {
                                content: '已提交补料',
                                 timestamp: info.makingsFileTime,
                                color: info.makingsFileTime ? '#0bbd87' : '',
                                url:info.makingsFile ? info.makingsFile :'',
                                urlText:'下载补料文件'
                                // timestamp: '2018-04-11'
                            },
                            {
                                content: '已提交VGM',
                               timestamp: info.vgmFileTime,
                                color: info.vgmFileTime ? '#0bbd87' : '',
                                url:info.vgmFile ? info.vgmFile :'',
                                urlText:'下载VGM文件'
                            },
                            {
                                content: '已发船',
                                timestamp: info.sailTime,
                                color: info.weStatus >= 6  ? '#0bbd87' : '',
                                url:'',
                            },
                            {
                                content: '已付款待出账',
                                timestamp: info.arrearageFileTime,
                                color: info.arrearageFileTime  ? '#0bbd87' : '',
                                 url:info.arrearageFile ? info.arrearageFile :'',
                                 urlText:'下载详细账单'
                            },
                            {
                                content: '交易成功',
                                timestamp: info.successfulFileTime,
                                color: info.weStatus == 8 ? '#0bbd87' : '',
                                 url:info.successfulFile ? info.successfulFile :'',
                                 urlText:'下载回单'
                            },
                             {
                                content: '已取消',
                                timestamp: info.payTime,
                                color: info.weStatus == 9 ? '#0bbd87' : '',
                                 url:''
                            }
                    ]
                    if (res.data.weSurchargeAmountDetail) {
                        res.data.surchargeAmountDetail = JSON.parse(res.data.weSurchargeAmountDetail);
                            console.log(res.data.surchargeAmountDetail);
                            let usdTotal = 0;
                            let cnyTotal = 0;
                            res.data.usdTotal = usdTotal;
                            // // debugger
                            res.data.cnyTotal = cnyTotal;
                            res.data.surchargeAmountDetail.forEach((ele1) => {
                            console.log(ele1,'ele1');
                            if (ele1.feeType == "CNY") {
                                cnyTotal += ele1.fee;
                                // debugger
                            }
                            if (ele1.feeType == "USD") {
                                usdTotal += ele1.fee;
                            }
                            // debugger
                            res.data.usdTotal = usdTotal;
                            // // debugger
                            res.data.cnyTotal = cnyTotal;
                        });
                    }
                     this.orderInfo = res.data
                    console.log( this.orderInfo)
            })
        },
     }
}
</script>
<style lang="scss" scoped>
    .main{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box{
        width: 1420px;
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        margin-top: 40px;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 80px;
        background: #fff;
        margin-bottom: 100px;
    }
    .boxTitle{
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px
    }
    .ddStatus{
        margin-top: 24px;
        width: 1380px;
        height: 48px;
        background: rgba(22, 119, 255, 0.1);
        box-shadow: 0px -1px 0px 0px #EEEEEE;
        border-radius: 4px;
        display: flex;
        font-weight: 500;
        align-items: center;
        justify-content: space-between;
    }
    .dcinfobox{
        display: flex;
        margin-top: 20px;
    }
    .dctitle{
        width: 953px;
        height: 48px;
        background: #F9F9F9;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        align-items: center;
        padding-left: 24px;
        font-weight: 500;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
    }
    .mt20{
        margin-top: 20px;
    }
    .leftbox{
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
            padding-bottom: 24px;
    }
    .lxrbox{
        margin-top: 28px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .lxrtittl{
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-left: 84px;
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px dashed rgba(204, 204, 204, 1);
    }
    .lxrtittl div{
        width: 100px;
    }
    .strong{
        font-weight: 500;
    }
    .dcbox{
        margin-top: 24px;

    }
    .dc{
        display: flex;
        justify-content: space-between;
        margin-left: 36px;
    }
    .dc div{
        width: 250px;
        font-weight: 500;
        font-size: 14px;
    }
    .mt12{
        margin-top: 12px;
    }
    .mt4{
        margin-top: 4px;
    }
    .qtsxx{
        font-size: 14px;
        font-weight: 500;
    }
    .extra1{
       width: 240px;
        height: 30px;
        background: #F5F5F5;
        border-radius: 2px;
        display: flex;
        padding-left: 34px;
        align-items: center;
        padding-right: 12px;
        // float: left;
        justify-content: space-between;
    }
    .extra1 img{
        width: 16px;
        height: 16px;
    }
    .dowonload{
        cursor: pointer;
    }
    .rightmian{
        width: 400px;
    }
    .link{
        text-align: right;
        cursor: pointer;
        margin-left: 50px;
        color: rgb(22, 119, 255);;
    }
</style>